import { FC } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import IconProps from './IconProps';

const ResourceIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00013 7.55556V16.4444H6.00013V7.55556H4.00013ZM4.00013 16.4444C4.00013 18.0091 5.24255 19.1564 6.63571 19.853C8.08868 20.5795 10.026 21 12.1112 21V19C10.2691 19 8.65088 18.6245 7.53014 18.0642C6.34958 17.4739 6.00013 16.8434 6.00013 16.4444H4.00013ZM12.1112 21C14.1965 21 16.1338 20.5795 17.5868 19.853C18.9799 19.1564 20.2224 18.0091 20.2224 16.4444H18.2224C18.2224 16.8434 17.8729 17.4739 16.6923 18.0642C15.5716 18.6245 13.9533 19 12.1112 19V21ZM20.2224 16.4444V7.55556H18.2224V16.4444H20.2224ZM4.00013 7.55556C4.00013 9.12022 5.24255 10.2676 6.63571 10.9641C8.08869 11.6906 10.026 12.1111 12.1112 12.1111V10.1111C10.2691 10.1111 8.65088 9.73566 7.53014 9.17529C6.34958 8.58501 6.00013 7.95457 6.00013 7.55556H4.00013ZM12.1112 12.1111C14.1965 12.1111 16.1338 11.6906 17.5868 10.9641C18.9799 10.2676 20.2224 9.12022 20.2224 7.55556H18.2224C18.2224 7.95457 17.8729 8.58501 16.6923 9.17529C15.5716 9.73566 13.9533 10.1111 12.1112 10.1111V12.1111ZM6 7.55556C6 7.15654 6.34946 6.5261 7.53001 5.93583C8.65075 5.37546 10.269 5 12.1111 5V3C10.0258 3 8.08856 3.42048 6.63558 4.14697C5.24242 4.84355 4 5.99089 4 7.55556H6ZM12.1111 5C13.9532 5 15.5715 5.37546 16.6922 5.93582C17.8728 6.5261 18.2222 7.15654 18.2222 7.55556H20.2222C20.2222 5.99089 18.9798 4.84355 17.5866 4.14697C16.1337 3.42048 14.1964 3 12.1111 3V5ZM18.2224 12C18.2224 12.399 17.8729 13.0294 16.6923 13.6197C15.5716 14.1801 13.9533 14.5556 12.1112 14.5556V16.5556C14.1965 16.5556 16.1338 16.1351 17.5868 15.4086C18.9799 14.712 20.2224 13.5647 20.2224 12H18.2224ZM12.1112 14.5556C10.2691 14.5556 8.65088 14.1801 7.53014 13.6197C6.34958 13.0294 6.00013 12.399 6.00013 12H4.00013C4.00013 13.5647 5.24255 14.712 6.63571 15.4086C8.08868 16.1351 10.026 16.5556 12.1112 16.5556V14.5556Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ResourceIcon;
